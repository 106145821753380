import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/nadcha_u_deti/" className="sel">
              {" "}
              &gt; NÁDCHA U DETÍ
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/deti/upchaty_nos_u_detC3AD/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/pokonaj_zatkany_nos-_dziecka_szybko_skutecznie.png)" }} />
                    </div>
                    <div className="c2">Upchatý nos u detí </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/iStock_63844397_LARGE.png)" }} />
                    </div>
                    <div className="c2">
                      Imunita dieťaťa – dbajte a posilňujte{" "}
                    </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/nadcha_u_deti/" className="quiz_btn" />
            </div>
            <div className="col2">
              <div className="wysiwyg">
                <div>
                  <img
                    alt="OLYNTH® rýchla a jemná liečba pre deti"
                    src="/web/kcfinder/uploads/images/sk/Nadcha_u_deti_SK.png"
                    style={{
                      width: "326px",
                      height: "55px"
                    }}
                  />
                </div>
                <div>&nbsp;</div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <div>
                    Keď sa u dieťaťa objaví nádcha, treba mu venovať mimoriadnu
                    pozornosť a zabezpečiť správnu starostlivosť. Detský noštek
                    je citlivejší a jeho anatómia sa od nosa dospelého líši.
                    Nosové kanáliky sú úzke a aj nepatrný opuch nosovej sliznice
                    dokáže skomplikovať dýchanie nosom. Rozdielna anatómia{" "}
                    <a href="/slovnik_pojmov/#sluchova_trubica" target="_blank">
                      Eustachovej trubice
                    </a>
                    &nbsp;(je kratšia, vedie vodorovne a je stále otvorená)
                    uľahčuje šírenie infekcií – viac ako 60 % infekcií horných
                    dýchacích ciest vedie k akútnemu zápalu stredného ucha
                    <sup>1</sup>.
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    Upchatý nos, problémy s dýchaním a zahlienenie spôsobujú, že
                    dieťa je unavené a podráždené a v noci má problémy so
                    spánkom. Mimoriadne dôležité je používať lieky, ktoré nos
                    rýchlo uvoľnia a zabezpečia voľné dýchanie nosom a odtekanie
                    hlienov. Zároveň sa tým pomáha znížiť riziko vzniku vážnych
                    komplikácií, ako je napríklad zápal stredného ucha, zápal
                    prinosových dútin alebo zápal priedušiek.
                  </div>
                  <div>&nbsp;</div>
                </div>
                <div>&nbsp;</div>
                <div>
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <sup>1</sup>Stockmann C, Ampofo K, Hersh AL, Carleton ST,
                    Korgenski K, Sheng X, Pavia AT, Byington CL. Seasonality of
                    acute otitis media and the role of respiratory viral
                    activity in children Pediatr Infect Dis J. 2013
                    Apr;32(4):314-9. doi: 10.1097/INF.0b013e31827d104e.PubMed
                    PMID: 23249910; PubMed Central PMCID: PM3618601{" "}
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="drugs">
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_Olynth_0.05_2023_Left_NEW.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> 0,05 %<br/>
                      nosový roztokový<br/>
                      sprej
                    </p>
                    <div className="desc">
                      RÝCHLÁ A ÚČINNÁ LIEČBA PRÍZNAKOV NÁDCHY U DETÍ OD 2 DO 6
                      ROKOV
                    </div>
                    <a href="/lieky/olynth_05/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_HA_0.05 perc_2023_left.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> HA 0,05 %<br/>
                      nosový roztokový<br/>
                      sprej
                    </p>
                    <div className="desc">
                    RÝCHLA ÚĽAVA OD  NÁDCHY.
                    PRE DETI OD 2 DO 6 ROKOV. ZVLHČUJE NOSOVÚ SLIZNICU VĎAKA 
                    TROM ZVLHČUJÚCIM LÁTKAM.
                    </div>
                    <a href="/lieky/olynth_ha_05/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_PLUS_0.5mg_left.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> PLUS<br />0,5 mg/50 mg/ml nosový roztokový sprej
                    </p>
                    <div className="desc">
                    RÝCHLO UVOĽŇUJE UPCHATÝ NOS U DETÍ OD<br />2 DO 6 ROKOV. 
                    URÝCHĽUJE HOJENIE PORANENEJ NOSOVEJ SLIZNICE.
                    </div>
                    <a href="/lieky/olynth_plus_05/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}

      </div>
    );
  }
}

export default Page;
